import { getFragmentData } from '@packages/gql/generated/shopping';
import { ProductRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductRecommendationFragmentFragmentDoc';
import { PrudsysHomepageDocument } from '@packages/gql/generated/shopping/PrudsysHomepageDocument';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'urql';
import { useRecoElement } from '../../../helpers/useRecoElement';
import type { ProductsRecoSliderProps, RdeType } from '../../../types';
import { RecoCarousel } from '../../RecoCarousel';
import { RecoCarouselSkeleton } from '../../RecoCarouselSkeleton';
import { RecoProductCard } from '../../RecoProductCard';
import { useProductsRecoTracking } from '../../RecoProductCard/useProductsRecoTracking';

export type PrudsysHomepageProductsRecoSliderProps = ProductsRecoSliderProps;

/* GraphQL */ `
  query PrudsysHomepage($locale: String!) {
    prudsysHomepage(locale: $locale) {
      title
      type
      recommendations {
        ...ProductRecommendationFragment
      }
      extendedViewUrl
    }
  }
`;

const RDE_TYPE: RdeType = 'prudsysHomepage';

/**
 * PrudsysHomepageProductsRecoSlider component description displayed in storybook
 * */
export const PrudsysHomepageProductsRecoSlider: FC<PrudsysHomepageProductsRecoSliderProps> = ({
  elementIndex = 0,
  fetchOnThreshold = false,
  onProductClick,
  onSeen,
  pageTemplate,
  preferTitle = false,
  title,
}) => {
  const { locale } = useIntl();
  const { isInThreshold, isInView, recoEl } = useRecoElement();

  const [{ data, error }] = useQuery({
    query: PrudsysHomepageDocument,
    variables: {
      locale: locale!,
    },
    context: useMemo(() => ({ suspense: false }), []),
    pause: fetchOnThreshold ? !isInThreshold : false,
    requestPolicy: 'network-only',
  });

  const recommendations = data ? data.prudsysHomepage.recommendations : undefined;

  /** Filtered out recommendations that don't have a product (product === null) */
  const filteredRecommendations = recommendations
    ? recommendations.filter(
        (recommendation) =>
          getFragmentData(ProductRecommendationFragmentFragmentDoc, recommendation).product,
      )
    : undefined;

  const rdeTypeAbbr = data?.prudsysHomepage.type;

  useProductsRecoTracking({
    elementIndex,
    pageTemplate,
    rdeType: RDE_TYPE,
    rdeTypeAbbr,
    recommendations: filteredRecommendations,
    title,
    trackingCondition: isInView && !!filteredRecommendations && filteredRecommendations.length > 0,
    onSeen,
  });

  if (
    (error && !filteredRecommendations) ||
    (filteredRecommendations && filteredRecommendations.length === 0)
  ) {
    return null;
  }

  return (
    <div ref={recoEl}>
      {!filteredRecommendations ? (
        <RecoCarouselSkeleton hasNoTitle={preferTitle && title.length === 0} />
      ) : (
        <RecoCarousel
          title={preferTitle ? title : data?.prudsysHomepage.title || title}
          loadingState={!filteredRecommendations}
        >
          {filteredRecommendations.map((recommendation, idx) => (
            <RecoProductCard
              pageTemplate={pageTemplate}
              // rule is disabled because order doesn't change between rerenders
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              elementIndex={elementIndex}
              position={idx}
              recommendationData={recommendation}
              productsLength={filteredRecommendations.length}
              rdeType={RDE_TYPE}
              onClick={(akl, sku) => {
                onProductClick?.(akl, sku);
              }}
            />
          ))}
        </RecoCarousel>
      )}
    </div>
  );
};
