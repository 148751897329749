import { Box, Grid, useDeviceType } from '@packages/shared';
import type { CategoryTeaserType, CategoryTeaserItemType } from './types';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';
import { CategoryTeaserItem } from './CategoryTeaserItem';

/**
 * The CategoryTeaser component provides a flexible and customizable layout for displaying a series of teaser items.
 * It allows CMS editors to easily update the home pages and promotional landing pages of web stores.
 * The component supports various layout options such as alignment, variant, spacing, and outline,
 * enabling a diverse range of presentation styles. It dynamically adjusts the grid layout based on the number of teaser items.
 *
 * @param {CategoryTeaserType} data - The data object containing the layout configuration and an array of teaser items.
 * The layout configuration includes alignment (left, center), variant (circle, rectangle, type_only),
 * outer spacing (valid CSS margin values), and outline (none, outline_thin). The teaser items are an array of
 * individual paragraph data to be displayed within the teaser.
 * @returns A React component that renders a grid of CategoryTeaserItems, each with its own styling based on the
 * provided layout options and arranged according to the specified number of items per row.
 */
export const CategoryTeaser = ({
  data: {
    // Options: left, center
    behavior_paragraph_alignment = 'left',
    // Options: 'circle', 'reactangle', 'type_only'
    behavior_paragraph_variant: layoutVariant = 'reactangle',
    // Options: valid css margin values like: '0', '1rem', '1rem 2rem 3rem 4rem'
    behavior_paragraph_outer_spacing: layoutOuterSpacing = '0',
    // Options: 'none', 'outline_thin'
    behavior_paragraph_outline: layoutOutline = 'none',
    // Options: true, false | Note: Lazy loading should actually always be activated by default
    behavior_paragraph_lazy_load: isLazyLoading = true,
    // Custom identifier for dynamic yield content replacement
    behavior_paragraph_custom_id: customId,
    // Call-to-action
    behavior_paragraph_cta_icon,
    behavior_paragraph_cta_size,
    behavior_paragraph_cta_type_and_alignment,
    behavior_paragraph_layout,
    // teaser items
    paragraph: teaserItems,
  },
}: {
  data: CategoryTeaserType;
}) => {
  const { isMobile } = useDeviceType();
  const isVariantTypeOnly = layoutVariant === 'type_only';
  const isVariantCircle = layoutVariant === 'circle';
  const cellsPerRow =
    (!isMobile && isVariantTypeOnly && teaserItems?.length > 8 && 3) ||
    (isMobile && isVariantTypeOnly && 1) ||
    (!isMobile && isVariantTypeOnly && 2) ||
    (isMobile && isVariantCircle && teaserItems?.length > 3 && 3) ||
    (isMobile && teaserItems?.length === 1 && 1) ||
    (isMobile && teaserItems?.length > 1 && 2) ||
    teaserItems?.length ||
    1;
  const colGap = isVariantTypeOnly ? '3.75rem' : '1.25rem';
  const rowGap = '1.25rem';
  const gridCondition = cellsPerRow > 1;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        height: '100%',
        backgroundColor: 'transparent',
        margin: layoutOuterSpacing,
        ...(isMobile && { padding: 3, pt: 0, pb: 0 }),
        ...(behavior_paragraph_alignment === 'center' && {
          alignContent: 'center',
          textAlign: 'center',
        }),
        ...(isVariantTypeOnly && {
          backgroundColor: 'text.light',
        }),
        '.category-teaser-item': {
          ...(isVariantTypeOnly && {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignContent: 'center',
            border: '1px',
            borderStyle: 'solid',
            borderColor: 'grey.main',
            '.subline': {
              textAlign: 'center',
            },
            pt: '0.5rem',
            pb: '0.5rem',
          }),
          ' img': {
            width: '100%',
            height: '100%',
            ...(layoutVariant === 'circle' && {
              borderRadius: '50%',
            }),
            ...(layoutOutline !== 'none' && {
              border: '1px',
              borderStyle: 'solid',
              borderColor: 'grey.main',
            }),
          },
        },
      }}
      {...(customId && { 'data-dy': customId })}
    >
      <Grid
        sx={{
          margin: '0 auto',
          ...(isVariantTypeOnly &&
            cellsPerRow === 2 && {
              maxWidth: '45rem',
            }),
        }}
        container
        columns={cellsPerRow}
        columnSpacing={0}
        spacing={0}
      >
        {teaserItems &&
          teaserItems.map((item: CategoryTeaserItemType, index: number) => (
            <Grid
              key={item.id}
              item
              xs={1}
              md={1}
              sx={{
                ...(gridCondition && {
                  flexBasis: `calc((100% - ${colGap} * (${cellsPerRow} - 1)) / ${cellsPerRow}) !important`,
                  [`&:nth-of-type(${cellsPerRow}n+0)`]: {
                    marginRight: '0',
                  },
                }),
                overflow: 'hidden',
                marginBottom: rowGap,
                marginRight: gridCondition ? colGap : 0,
              }}
            >
              <CategoryTeaserItem
                /* eslint-disable-next-line react/no-array-index-key */
                key={`category-teaser-item-${index}`}
                data={{
                  ...item,
                  behavior_paragraph_alignment,
                  behavior_paragraph_cta_icon,
                  behavior_paragraph_cta_size,
                  behavior_paragraph_cta_type_and_alignment,
                  behavior_paragraph_layout,
                }}
                isTypeOnly={isVariantTypeOnly}
                isLazyLoading={isLazyLoading}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({
  [ComponentTypes.CategoryTeaser]: CategoryTeaser,
});
