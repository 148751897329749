import { getFragmentData } from '@packages/gql/generated/shopping';
import { CategoryRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryRecommendationFragmentFragmentDoc';
import { PrudsysCategoryToCategoriesDocument } from '@packages/gql/generated/shopping/PrudsysCategoryToCategoriesDocument';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'urql';
import { useRecoElement } from '../../../helpers/useRecoElement';
import type { CategoriesRecoSliderProps, RdeType, RequestParams } from '../../../types';
import { RecoCarousel } from '../../RecoCarousel';
import { RecoCarouselSkeleton } from '../../RecoCarouselSkeleton';
import { RecoCategoryCard } from '../../RecoCategoryCard';
import { useCategoriesRecoTracking } from '../../RecoCategoryCard/useCategoriesRecoTracking';

export type PrudsysCategoryToCategoriesProps = Pick<RequestParams, 'categoryId'> &
  CategoriesRecoSliderProps;

/* GraphQL */ `
  query PrudsysCategoryToCategories($categoryId: String!, $locale: String!) {
    prudsysCategoryToCategories(categoryId: $categoryId, locale: $locale) {
      title
      type
      recommendations {
        ...CategoryRecommendationFragment
      }
    }
  }
`;

const RDE_TYPE: RdeType = 'prudsysCategoryToCategories';

/**
 * SimilarProductsRecoSlider component description displayed in storybook
 * */
export const PrudsysCategoryToCategoriesRecoSlider: FC<PrudsysCategoryToCategoriesProps> = ({
  categoryId,
  elementIndex = 0,
  fetchOnThreshold = false,
  pageTemplate,
  preferTitle = false,
  sourceCategoryId,
  title,
}) => {
  const { isInThreshold, isInView, recoEl } = useRecoElement();
  const { locale } = useIntl();

  const [{ data, error }] = useQuery({
    query: PrudsysCategoryToCategoriesDocument,
    variables: {
      categoryId,
      locale: locale!,
    },
    context: useMemo(() => ({ suspense: false }), []),
    pause: fetchOnThreshold ? !isInThreshold : false,
    requestPolicy: 'network-only',
  });

  const recommendations = data ? data.prudsysCategoryToCategories.recommendations : undefined;

  /** Filtered out recommendations that don't have a category (category === null) */
  const filteredRecommendations = recommendations
    ? recommendations.filter(
        (recommendation) =>
          getFragmentData(CategoryRecommendationFragmentFragmentDoc, recommendation).category,
      )
    : undefined;

  const rdeTypeAbbr = data?.prudsysCategoryToCategories.type;

  useCategoriesRecoTracking({
    elementIndex,
    recommendations: filteredRecommendations,
    rdeType: RDE_TYPE,
    rdeTypeAbbr,
    sourceCategoryId,
    title,
    trackingCondition: isInView && !!recommendations && recommendations.length > 0,
  });

  if (
    (error && !filteredRecommendations) ||
    (filteredRecommendations && filteredRecommendations.length === 0)
  ) {
    return null;
  }

  return (
    <div ref={recoEl}>
      {!filteredRecommendations ? (
        <RecoCarouselSkeleton type="CAT" hasNoTitle={preferTitle && title.length === 0} />
      ) : (
        <RecoCarousel
          title={preferTitle ? title : data?.prudsysCategoryToCategories.title || title}
          loadingState={!filteredRecommendations}
        >
          {filteredRecommendations.map((recommendation, idx) => (
            <RecoCategoryCard
              pageTemplate={pageTemplate}
              // rule is disabled because order doesn't change between rerenders
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              position={idx}
              recommendationData={recommendation}
              categoriesLength={filteredRecommendations.length}
              rdeType={RDE_TYPE}
            />
          ))}
        </RecoCarousel>
      )}
    </div>
  );
};
