import { getFragmentData } from '@packages/gql/generated/shopping';
import { CriteoViewHomeProcutsDocument } from '@packages/gql/generated/shopping/CriteoViewHomeProcutsDocument';
import { ProductRecommendationFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductRecommendationFragmentFragmentDoc';
import { type CriteoPageId } from '@packages/gql/generated/shopping/graphql';
import { useDeviceType } from '@packages/shared';
import { useCookies } from '@packages/utilities';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { useRecoElement } from '../../../helpers/useRecoElement';
import type { ProductsRecoSliderProps, RdeType } from '../../../types';
import { RecoCarousel } from '../../RecoCarousel';
import { RecoCarouselSkeleton } from '../../RecoCarouselSkeleton';
import { RecoProductCard } from '../../RecoProductCard';
import { useProductsRecoTracking } from '../../RecoProductCard/useProductsRecoTracking';

export type CriteoViewHomeProductsRecoSliderProps = Omit<ProductsRecoSliderProps, 'preferTitle'>;

/* GraphQL */ `
  query CriteoViewHomeProcuts($pageId: CriteoPageId!) {
    criteoViewHome(params: { pageId: $pageId }) {
      type
      recommendations {
        ...ProductRecommendationFragment
      }
      ...CriteoPlacementBeaconsFragment
    }
  }
`;

const RDE_TYPE: RdeType = 'criteoViewHome';

/**
 * CriteoViewHomeProductsRecoSlider component description displayed in storybook
 * */
export const CriteoViewHomeProductsRecoSlider: FC<CriteoViewHomeProductsRecoSliderProps> = ({
  elementIndex = 0,
  fetchOnThreshold = false,
  onProductClick,
  onSeen,
  pageTemplate,
  title,
}) => {
  const { isMobile } = useDeviceType();
  const { isInThreshold, isInView, recoEl } = useRecoElement();
  const { getCookies } = useCookies();
  const { appView } = getCookies();
  const isApp = appView === 'true';

  const pageId: CriteoPageId = (() => {
    if (isApp) return 'App';
    if (isMobile) return 'Mobile';
    return 'Desktop';
  })();

  const [{ data, error }] = useQuery({
    query: CriteoViewHomeProcutsDocument,
    variables: {
      pageId,
    },
    context: useMemo(() => ({ suspense: false }), []),
    pause: fetchOnThreshold ? !isInThreshold : false,
    requestPolicy: 'network-only',
  });

  const recommendations = data ? data.criteoViewHome.recommendations : undefined;

  /** Filtered out recommendations that don't have a product (product === null) */
  const filteredRecommendations = recommendations
    ? recommendations.filter(
        (recommendation) =>
          getFragmentData(ProductRecommendationFragmentFragmentDoc, recommendation).product,
      )
    : undefined;

  const rdeTypeAbbr = data?.criteoViewHome.type;

  useProductsRecoTracking({
    elementIndex,
    pageTemplate,
    placementBeaconsData: data?.criteoViewHome,
    rdeType: RDE_TYPE,
    rdeTypeAbbr,
    recommendations: filteredRecommendations,
    title,
    trackingCondition: isInView && !!filteredRecommendations && filteredRecommendations.length > 0,
    onSeen,
  });

  if (
    (error && !filteredRecommendations) ||
    (filteredRecommendations && filteredRecommendations.length === 0)
  ) {
    return null;
  }

  return (
    <div ref={recoEl}>
      {!filteredRecommendations ? (
        <RecoCarouselSkeleton />
      ) : (
        <RecoCarousel title={title} loadingState={!filteredRecommendations}>
          {filteredRecommendations.map((recommendation, idx) => (
            <RecoProductCard
              pageTemplate={pageTemplate}
              // rule is disabled because order doesn't change between rerenders
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              elementIndex={elementIndex}
              position={idx}
              recommendationData={recommendation}
              productsLength={filteredRecommendations.length}
              rdeType={RDE_TYPE}
              onClick={(akl, sku) => {
                onProductClick?.(akl, sku);
              }}
            />
          ))}
        </RecoCarousel>
      )}
    </div>
  );
};
