import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import type { ParagraphMeta } from '../../../../interfaces';
import { ComponentTypes } from '../../../../interfaces';
import type {
  NewsletterSubscribeFormType,
  NewsletterSubscriptionFormType,
} from '../NewsletterSubscribeForm/types';
import { registerComponents } from '../../ComponentRecognizer/componentRegistry';
import { unsubscribeMessages } from '../NewsletterUnsubscribeForm/helpers/messages';

const DynamicComponentNewsletterSubscribeForm = dynamic(
  () =>
    import(
      /* webpackChunkName: 'CMS_NewsletterSubscribeForm' */ '../NewsletterSubscribeForm/NewsletterSubscribeForm'
    ),
);
const DynamicComponentNewsletterUnsubscribeForm = dynamic(
  () =>
    import(
      /* webpackChunkName: 'CMS_NewsletterUnsubscribeForm' */ '../NewsletterUnsubscribeForm/NewsletterUnsubscribeForm'
    ),
);

export type NewsletterLegacyUnsubscribeFormType = ParagraphMeta & {
  type: 'newsletter';
  field_newsletter_type: 'unsubscribe';
  field_area_key: 'serviceHilfeFormular' | 'serviceHilfe';
};

export type NewsletterUnsubscribeFormType = ParagraphMeta & {
  type: 'newsletter_unsubscribe';
  field_area_key: 'serviceHilfeFormular' | 'serviceHilfe';
  field_headline_unsubscribe?: string;
  field_text_unsubscribe?: string;
  field_button_label?: string;
  field_button_label_mobile?: string;
  token?: string;
};

/**
 * Legacy component for the old Newsletter paragraph.
 * Must be kept for compatibility reasons as long as the old Paragraph in Drupal is still in use.
 */
export const LegacyNewsletter = ({
  data,
  token,
}: {
  data: NewsletterSubscribeFormType | NewsletterLegacyUnsubscribeFormType;
  token?: string;
}) => {
  const intl = useIntl();
  if (data.field_newsletter_type === 'unsubscribe') {
    return (
      <DynamicComponentNewsletterUnsubscribeForm
        areaKey={data.field_area_key}
        headline={intl.formatMessage(unsubscribeMessages.legacyHeadline)}
        copyText={intl.formatMessage(unsubscribeMessages.legacyDescription)}
        token={token}
      />
    );
  }
  if (data.field_newsletter_type === ComponentTypes.NewsletterSubscribe) {
    return <DynamicComponentNewsletterSubscribeForm data={data} />;
  }
  /* istanbul ignore next */
  return <span />;
};

export const NewsletterSubscribe = ({ data }: { data: NewsletterSubscriptionFormType }) => (
  <DynamicComponentNewsletterSubscribeForm data={data} />
);

/**
 * New Newsletter Unsubscribe component that passes headline and texts to the form.
 */
export const NewsletterUnsubscribe = ({
  data,
  token,
}: {
  data: NewsletterUnsubscribeFormType;
  token?: string;
}) => {
  const {
    field_area_key: areaKey,
    field_headline_unsubscribe: unsubscribeHeadline,
    field_text_unsubscribe: unsubscribeText,
    field_button_label: buttonLabel,
    field_button_label_mobile: buttonLabelMobile,
  } = data;

  return (
    <DynamicComponentNewsletterUnsubscribeForm
      areaKey={areaKey}
      headline={unsubscribeHeadline}
      copyText={unsubscribeText || ''}
      buttonLabel={buttonLabel}
      buttonLabelMobile={buttonLabelMobile}
      token={token}
    />
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.Newsletter]: LegacyNewsletter });
registerComponents({ [ComponentTypes.NewsletterUnsubscribe]: NewsletterUnsubscribe });
registerComponents({ [ComponentTypes.NewsletterSubscription]: NewsletterSubscribe });
