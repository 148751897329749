import type {
  RecoCategoryTracking,
  RecoBrandTracking,
  RecoProductTracking,
} from '@packages/gql/generated/shopping/graphql';

/** What the rde-result is based on */
export type RecoLogic =
  | RecoBrandTracking['logic']
  | RecoProductTracking['logic']
  | RecoCategoryTracking['logic'];

export type GetClickTrackingURLExtensionDataType<T extends 'brands' | 'categories' | 'products'> =
  (T extends 'brands' | 'categories'
    ? {
        /**
         * name of the category or brand, that has been clicked
         */
        name: string;
        /**
         * number of items in slider
         */
        itemsToLoad: number;
      }
    : T extends 'products'
      ? {
          sourceArticleNo: string;
          targetOrderNumber: string;
          outputElementNo: number;
          productsToLoad: number;
        }
      : never) & {
    /** pageTemplate where the RecoSlider is placed */
    pageTemplate: string;
    /**
     * cid of the item, that has been clicked
     */
    cid: string;
    /**
     * number of slide
     */
    position: number;
    recoLogic: RecoLogic;
    rdeType: string;
  };

/**
 * @param outputElementNo If more than one RecoSlider exists on the page the slider has to "know" which position it has to send that position to tracking
 */
export const getClickTrackingProductsURLExtension = ({
  sourceArticleNo,
  targetOrderNumber,
  cid,
  outputElementNo,
  position,
  pageTemplate,
  productsToLoad,
  recoLogic,
  rdeType,
}: GetClickTrackingURLExtensionDataType<'products'>) =>
  // To support click tracking the URL has to be extended with the needed parameters

  `&ref=reco&lmPromo=la,1,hk,${pageTemplate},fl,` +
  `${rdeType}_${productsToLoad}_${position}_${cid}_${sourceArticleNo}_${targetOrderNumber}_${recoLogic}_` +
  `OutputElement${outputElementNo}`;

export const getClickTrackingBrandsCategoriesURLExtension = <T extends 'categories' | 'brands'>({
  cid,
  position,
  pageTemplate,
  recoLogic,
  rdeType,
  itemsToLoad,
  name,
}: GetClickTrackingURLExtensionDataType<T>) => {
  // To support click tracking the URL has to be extended with the needed parameters
  const urlExtension =
    // eslint-disable-next-line prefer-template
    '#ref=reco&lmPromo=la,1,hk,' +
    pageTemplate +
    ',fl,' +
    `${rdeType}_` +
    itemsToLoad +
    '_' +
    position +
    '_' +
    cid +
    '_' +
    name +
    '_' +
    recoLogic;

  return urlExtension;
};
